import React, { useState } from 'react';
import { motion } from 'framer-motion'; // Importing framer-motion for animations
import './Home.css';

const Home: React.FC = () => {
	const [showModal, setShowModal] = useState(false);

	const toggleModal = () => setShowModal(!showModal);

	return (
		<div className="home-container">
			{/* Logo Section */}
			<motion.div
				className="logo-container"
				initial={{ opacity: 0, scale: 0.6 }}
				animate={{ opacity: 1, scale: 1 }}
				transition={{ duration: 1.5, ease: 'easeOut' }}>
				<img src="/matty-logo.png" alt="Band Logo" className="band-logo" />
			</motion.div>

			{/* Animated Play Button */}
			<motion.button
				className="play-button"
				whileHover={{ scale: 1.1, backgroundColor: '#e63900' }} // Scale and color change on hover
				whileTap={{ scale: 0.95 }} // Slightly shrink the button on click
				transition={{ duration: 0.3 }}
				onClick={toggleModal}>
				Watch our promo video!
			</motion.button>

			{/* Modal */}
			{showModal && (
				<div className="modal">
					<div className="modal-content">
						<iframe
							src="https://www.youtube.com/embed/fDiyzKIlUCM?si=RCZscrJ5yA8QEgan"
							title="YouTube video player"
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
							allowFullScreen></iframe>
						<button className="close-modal" onClick={toggleModal}>
							Close
						</button>
					</div>
				</div>
			)}

			{/* Background Animation */}
			<div className="background-animation">
				<div className="stars"></div>
			</div>
		</div>
	);
};

export default Home;
