import React from 'react';
import { motion } from 'framer-motion';
import './ShowDates.css';

const ShowDates: React.FC = () => {
	const shows = [
		{
			date: '2/8/25',
			venue: "Train's Tavern",
			location: 'Lebanon, ME',
			time: '7-11pm',
		},
		{
			date: '3/1/25',
			venue: 'Last Chair Brewery',
			location: 'Plymouth, NH',
			time: 'TBD',
		},
		{
			date: '3/15/25',
			venue: 'Eastside Tavern & Busters Billiards',
			location: 'Rochester, NH',
			time: '7-11pm',
		},
		{
			date: '4/25/25',
			venue: 'Private Event - River Day @ New England College',
			location: '',
			time: '',
		},
		{
			date: '4/26/25',
			venue: 'The Oaks Golf Links',
			location: 'Somersworth, NH',
			time: 'TBD',
		},
		{
			date: '5/2/25',
			venue: 'Weathervane Restaurant & Lounge',
			location: 'Readfield, ME',
			time: '9pm-12am',
		},
		{
			date: '5/3/25',
			venue: 'The Porthole Restaurant & Pub',
			location: 'Portland, ME',
			time: '6-9pm',
		},
		{
			date: '5/17/25',
			venue: 'Northwood NH Food Truck Festival',
			location: 'Northwood, NH',
			time: '2-6pm',
		},
		{
			date: '6/14/25',
			venue: 'The Porthole Restaurant & Pub',
			location: 'Portland, ME',
			time: '6-9pm',
		},
		{
			date: '6/21/25',
			venue: 'Private Event',
			location: '',
			time: '',
		},
		{
			date: '6/26/25',
			venue:
				"iHeart Radio’s Greg & The Morning Buzz’s 2025 'Luau On The Lake' on Lake Winnipesaukee",
			location: '',
			time: '7-10pm',
		},
		{
			date: '7/3/25',
			venue: 'The Porthole Restaurant & Pub',
			location: 'Portland, ME',
			time: '6-9pm',
		},
		{
			date: '7/11/25',
			venue: 'Bentley’s Saloon',
			location: 'Arundel, ME',
			time: '7-11pm',
		},
		{
			date: '7/12/25',
			venue: "The Garage at the Governor's Inn",
			location: 'Rochester, NH',
			time: '7-10pm',
		},
		{
			date: '7/19/25',
			venue: 'Iron Tails Saloon',
			location: 'Acton, ME',
			time: '7-11pm',
		},
		{
			date: '8/9/25',
			venue: 'Westward Shores Cottages & RV Resort',
			location: 'Ossipee Lake, Ossipee, NH',
			time: '7-10pm',
		},
		{
			date: '8/22/25',
			venue: 'Bentley’s Saloon',
			location: 'Arundel, ME',
			time: '7-11pm',
		},
		{
			date: '8/23/25',
			venue: 'The Garage at the Governor’s Inn',
			location: 'Rochester, NH',
			time: '7-10pm',
		},
		{
			date: '9/6/25',
			venue: 'Thomas Laighton Party Cruise! - Isles of Shoals Steamship Co',
			location: 'out of Portsmouth, NH',
			time: '7-10pm',
		},
		{
			date: '9/27/25',
			venue: 'Iron Tails Saloon (combo show with 13 Black!!)',
			location: 'Acton, ME',
			time: '7-11pm',
		},
		{
			date: '10/4/25',
			venue: 'Powderkeg Beer and Chili Fest',
			location: 'Exeter, NH',
			time: '12-5pm',
		},
		{
			date: '11/15/25',
			venue: 'Train’s Tavern',
			location: 'Lebanon, ME',
			time: '7-11pm',
		},
		// {
		// 	date: '8/3',
		// 	venue: 'Iron Tails Saloon',
		// 	location: 'Acton, ME',
		// 	time: '7-11pm',
		// },
		// {
		// 	date: '8/9',
		// 	venue: 'Bentley’s Saloon',
		// 	location: 'Arundel, ME',
		// 	time: '7-11pm',
		// },
		// {
		// 	date: '8/10',
		// 	venue: 'The Garage at The Governors Inn',
		// 	location: 'Rochester, NH',
		// 	time: '7-10pm (outdoor show!) $5 cover',
		// },
		// {
		// 	date: '9/8',
		// 	venue: 'Hampton Beach Seafood Festival',
		// 	location: 'Hampton, NH',
		// 	time: '12pm-2:30pm',
		// },
		// {
		// 	date: '9/14',
		// 	venue: 'The Oaks Golf Links',
		// 	location: 'Somersworth, NH',
		// 	time: 'time TBA',
		// },
		// {
		// 	date: '10/5',
		// 	venue: 'Powderkeg Beer and Chili Fest',
		// 	location: 'Exeter, NH',
		// 	time: 'time TBA',
		// },
		// {
		// 	date: '10/11',
		// 	venue: "Train's Tavern",
		// 	location: 'Lebanon, ME',
		// 	time: '7-11pm',
		// },
		// {
		// 	date: '10/26',
		// 	venue: 'Champions Sports Bar',
		// 	location: 'Biddeford, ME',
		// 	time: '8:30pm-12:30am',
		// },
		// {
		// 	date: '11/9',
		// 	venue: 'East Side Club',
		// 	location: 'Manchester, NH (members & guests only)',
		// 	time: '7-11pm',
		// },
		// {
		// 	date: '11/23',
		// 	venue: 'Train’s Tavern',
		// 	location: 'Lebanon, ME',
		// 	time: '7-11pm',
		// },
		// {
		// 	date: '11/30',
		// 	venue: 'The Oaks Golf Links - Ugly Sweater Party!!',
		// 	location: 'Somersworth, NH',
		// 	time: '7-10pm',
		// },
	];

	// Helper function to parse the show date
	const parseDate = (dateStr: string) => {
		// Split the date by "/"
		const parts = dateStr.split('/');
		const month = Number(parts[0]) - 1; // Month is 0-indexed
		const day = Number(parts[1]);
		const year =
			parts.length === 3 ? 2000 + Number(parts[2]) : new Date().getFullYear(); // If year exists, use it, otherwise use current year

		return new Date(year, month, day);
	};

	// Get today's date and set "yesterday"
	const today = new Date();
	const yesterday = new Date(today);
	yesterday.setDate(today.getDate() - 1);

	// Filter for upcoming shows (future dates) from 2025
	const upcomingShows = shows.filter((show) => {
		// Parse the date
		const showDate = parseDate(show.date);

		// Only include shows from 2025 and that are in the future
		return show.date.includes('25') && showDate >= yesterday;
	});

	return (
		<div className="show-dates-container">
			<h1 className="show-dates-title">Upcoming Shows</h1>
			<ul className="show-dates-list">
				{upcomingShows.map((show, index) => (
					<motion.li
						key={index}
						className="show-date-item"
						initial={{ opacity: 0, y: 20 }}
						animate={{ opacity: 1, y: 0 }}
						transition={{ delay: index * 0.2, duration: 0.6 }}>
						<div className="date">{show.date}</div>
						<div className="details">
							<div className="venue">{show.venue}</div>
							<div className="location">{show.location}</div>
							<div className="time">{show.time}</div>
						</div>
					</motion.li>
				))}
			</ul>
		</div>
	);
};

export default ShowDates;
